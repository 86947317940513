<template>
  <v-layout>
    <v-app-bar color="white" dark fixed app>
      <logo />

      <v-toolbar-items>
        <v-btn exact v-for="item in menu" :key="item.icon" :to="item.link" text color="blue darken-1">
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>

      <v-spacer />

      <user />
    </v-app-bar>

    <v-container fluid>
      <router-view />
    </v-container>
  </v-layout>
</template>

<script>
import Logo from '@/components/AppBar/Logo'
import User from '@/components/AppBar/User'

export default {
  components: {
    Logo,
    User
  },

  data() {
    return {
      menu: [
        {
          title: 'ДДС',
          link: '/filial_manager'
        },
        {
          title: 'Поверки',
          link: '/filial_manager/verifications'
        },
        {
          title: 'Создать поверку',
          link: '/filial_manager/verifications/add'
        },
        {
          title: 'Поверители',
          link: '/filial_manager/users/verifier'
        },
        {
          title: 'Установки',
          link: '/filial_manager/devices'
        }
      ]
    };
  },

  beforeMount() {
    if (this.$store.getters.user.role !== 'filial_manager') {
      this.$router.push({path: `/${this.$store.getters.user.role}`})
    }
  }
}
</script>
